<script>
export default {
  props: {
    data: Array,
    columns: Array,
    filterKey: String,
  },
  data() {
    return {
      sortKey: "",
      sortOrders: this.columns.reduce((o, key) => ((o[key] = 1), o), {}),
    };
  },
  computed: {
    filteredData() {
      const sortKey = this.sortKey;
      const filterKey = this.filterKey && this.filterKey.toLowerCase();
      const order = this.sortOrders[sortKey] || 1;
      let data = this.data;
      if (filterKey) {
        data = data.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      if (sortKey) {
        data = data.slice().sort((a, b) => {
          a = a[sortKey];
          b = b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    },
  },
  methods: {
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<template>
  <table class="table table-striped" v-if="filteredData.length">
    <thead>
      <tr>
        <th
          v-for="(key, i) in columns"
          :key="i"
          @click="sortBy(key)"
          :class="{ active: sortKey == key }"
        >
          {{ capitalize(key) }}
          <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <TransitionGroup name="list">
        <tr v-for="(entry, j) in filteredData" :key="j">
          <td v-for="(key, k) in columns" :key="k">
            <router-link
              :to="{ name: `MoreDetails`, params: { orderId: entry.id } }"
            >
              <div class="router-link-div">{{ entry[key] }}</div>
            </router-link>
          </td>
        </tr>
      </TransitionGroup>
    </tbody>
  </table>
  <p v-else>No matches found.</p>
</template>

<style lang="scss" scoped>
table {
  background-color: #fff;
  tbody tr {
    border-bottom: 0px;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.01);
      box-shadow: 0px 0px 20px #00000070;
    }
    td {
      border-bottom: 0px;
      padding: 10px;
    }
  }
}
.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.6s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
