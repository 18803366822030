<template>
  <transition
    appear
    appear-from-class="from"
    ppear-to-class="to"
    appear-active-class="apa"
  >
    <form @click.prevent>
      <div class="form-c">
        <div class="row g-3 align-items-center">
          <div class="col-auto d-block mx-auto">
            <h1 class="my-4">Sign Up</h1>
            <div
              class="form-floating mb-3"
              :class="{ 'form-group-error': v$.name.$error }"
            >
              <input
                type="text"
                class="w250 form-control"
                id="floatNameIs"
                placeholder="Your Name Is"
                v-model.trim="name"
              />
              <label for="floatNameIs">Your Name Is</label>
              <span class="error-feedback" v-if="v$.name.$error">{{
                v$.name.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center">
          <div class="col-auto d-block mx-auto">
            <div
              class="form-floating mb-3"
              :class="{ 'form-group-error': v$.email.$error }"
            >
              <input
                type="email"
                class="w250 form-control"
                id="floatEmailIs"
                placeholder="Your Email Is"
                v-model.trim="email"
              />
              <label for="floatEmailIs">Your Email Is</label>
              <span class="error-feedback" v-if="v$.email.$error">{{
                v$.email.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center">
          <div class="col-auto d-block mx-auto">
            <div
              class="form-floating mb-3"
              :class="{ 'form-group-error': v$.pass.$error }"
            >
              <input
                type="password"
                class="w250 form-control"
                id="floatPassIs"
                placeholder="Your Password Is"
                v-model.trim="pass"
              />
              <label for="floatPassIs">Your Password Is</label>
              <span class="error-feedback" v-if="v$.pass.$error">{{
                v$.pass.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <button
              type="submit"
              @click="validateEmailBeforeSignUp()"
              class="w250 btn btn-success"
            >
              Sign Up Now
            </button>
          </div>
        </div>
        <div class="row g-3 align-items-center mb-3">
          <div class="col-auto d-block mx-auto">
            <button
              type="button"
              @click="LoginPage()"
              class="w250 btn btn-primary"
            >
              Login Now
            </button>
          </div>
        </div>
        <div class="row g-3 align-items-center">
          <div
            class="col-auto d-block mx-auto alert alert-success"
            v-if="successMessage.length > 0"
          >
            {{ successMessage }}
          </div>
          <div
            class="col-auto d-block mx-auto alert alert-danger"
            v-if="errorMessage.length > 0"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </form></transition
  >
</template>

<script>
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
export default {
  name: "SignUpForm",
  data() {
    return {
      v$: useValidate(),
      name: "",
      pass: "",
      email: "",
      successMessage: "",
      errorMessage: "",
      userEmailExists: "",
    };
  },
  validations() {
    return {
      name: { required, minLength: minLength(10) },
      pass: { required, minLength: minLength(10) },
      email: { required, email },
    };
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.$router.push({ name: "LogIn" });
    }
  },
  methods: {
    LoginPage() {
      this.$router.push({ name: "LogIn" });
    },
    async validateEmailBeforeSignUp() {
      let res = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/users?email=${this.email}`
      );
      if (res.status == 200) {
        this.userEmailExists = res.data;
        if (this.userEmailExists.length != 1) {
          this.successMessage = "";
          this.errorMessage = "";
          this.signUpNow();
        } else {
          this.successMessage = "";
          this.errorMessage = "This email already exists..";
        }
      }
    },
    async signUpNow() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("Form Validated Successfully");
        let result = await axios.post(
          "https://my-json-server.typicode.com/y-alhouri/api-test/users",
          {
            name: this.name,
            email: this.email,
            pass: this.pass,
          }
        );
        if (result.status == 201) {
          console.log("Added New User Successfully");
          //save user data in local storage
          localStorage.setItem("user-info", JSON.stringify(result.data));
          console.log(result);
          console.log(JSON.stringify(result.data));
          this.successMessage = "Loading ...";
          this.errorMessage = "";
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        } else {
          this.successMessage = "";
          this.errorMessage = "Something went wrong, try again!";
        }
      } else {
        this.successMessage = "";
        this.errorMessage = "Must Fill in all Fields!";
      }
    },
  },
};
</script>

<style>
.error-feedback {
  color: red;
  font-size: 0.85em;
}
.form-c {
  width: 43%;
  max-width: 400px;
  height: 70vh;
  background-color: white;
  box-shadow: 0px 0px 26px -13px black;
  margin: 70px auto;
  border-radius: 30px;
  padding-top: 20px;
}
.w250 {
  width: 100% !important;
}
</style>
