<template>
  <div class="MealsDisplay text-start">
    <h3 class="Menu-title">Menu</h3>
    <ul class="subs-bar">
      <li
        class="subcat-name"
        @click="activateSubCatrgory($event)"
        v-for="(sub, j) in subCategories"
        :key="j"
        :data-category="sub.categoryId"
        :data-subcat="sub.id"
      >
        {{ sub.name }}
      </li>
    </ul>
    <div class="meals">
      <div
        class="meal"
        v-for="(meal, k) in meals"
        :key="k"
        :data-subcat="meal.subCategoryId"
      >
        <div>
          <img :src="meal.image" class="card-img-top rounded-1" alt="..." />
          <div>
            <h5 class="cardtitle">{{ meal.name }}</h5>

            <p>{{ meal.price }} $</p>

            <input
              class="btn add-inp btn-outline-warning col-7"
              type="number"
              min="1"
              @change="checkQty($event, quantities[k])"
              @keyup="checkQty($event, quantities[k])"
              v-model="quantities[k]"
            />
            <button
              class="btn add-btn btn-outline-warning col-3"
              @click="addOrder(meal, quantities[k], k)"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MealsDisplay",
  data() {
    return {
      categories: [],
      subCategories: [],
      meals: [],
      quantities: [],
    };
  },
  mounted() {
    this.getSubCategories();
    this.getMeals();
  },
  methods: {
    async getSubCategories() {
      let resOfSubCats = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/subcategories`
      );
      if (resOfSubCats.status == 200) {
        this.subCategories = resOfSubCats.data;
      } else {
        console.log(resOfSubCats);
      }
    },
    async getMeals() {
      let resOfMeals = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/meals`
      );
      if (resOfMeals.status == 200) {
        this.meals = resOfMeals.data;
        for (let m = 0; m < this.meals.length; m++) {
          this.quantities[m] = 1;
        }
      } else {
        console.log(resOfMeals);
      }
    },
    activateSubCatrgory(e) {
      if (e.target.classList[e.target.classList.length - 1] !== "active-sub") {
        let meal = document.getElementsByClassName("meal"),
          subCatName = document.getElementsByClassName("subcat-name"),
          activeMeals = [];
        for (let f = 0; f < subCatName.length; f++) {
          subCatName[f].classList.remove("active-sub");
        }
        e.target.classList.add("active-sub");
        for (let r = 0; r < meal.length; r++) {
          meal[r].classList.remove("active-meals");
          if (meal[r].dataset.subcat == e.target.dataset.subcat) {
            activeMeals.push(meal[r]);
          }
        }
        activeMeals.forEach((e, i) => {
          setTimeout(() => {
            e.classList.add("active-meals");
          }, i * 100);
        });
        activeMeals = [];
      }
    },
    addOrder(meal, qty, k) {
      let isMealAdded = this.$store.state.listOfOrders.find(
        ({ mealName }) => mealName == meal.name
      );
      qty <= 0 ? (qty = 1) : "";
      if (isMealAdded == undefined) {
        this.$store.state.listOfOrders.unshift({
          mealName: meal.name,
          mealPrice: meal.price,
          mealSales: meal.sales,
          mealImg: meal.image,
          mealQty: qty,
        });
      } else {
        isMealAdded.mealQty += qty;
      }
      this.quantities[k] = 1;
      console.log(this.$store.state.listOfOrders);
      this.finalPrice += meal.price;
    },
    checkQty(e, qty) {
      console.log(e.target.value);
      if (e.target.value < 0) {
        e.target.value = 1;
        qty = 1;
      } else {
        console.log(qty);
      }
    },
  },
};
</script>
<style lang="scss">
.MealsDisplay {
  margin-top: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .Menu-title {
    color: #ff9b55;

    font-family: system-ui;
    margin: 18px;
  }
  .subs-bar {
    background-color: rgb(255 255 255);
    width: 96%;
    backdrop-filter: brightness(1);
    border-radius: 15px;
    box-shadow: 0px 0px 18px -16px black;
  }
}
.subcat-name {
  position: relative;
  animation-name: fade;
  animation-duration: 0.6s;
  padding: 8px 10px;
  margin: 2px;
  border-radius: 10px;
  display: none;
  transition: all 0.3s ease;
  &::before {
    content: "";
    transition: all 0.3s ease;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    width: 95%;
    background-color: #ff9b55;
    border-radius: 100px;
    z-index: -1;
    transform: scale(0);
  }
}
.meals {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.meal {
  animation-name: fade;
  animation-duration: 0.6s;
  transition: all 0.3s ease;
  background: white;
  display: none;
  margin: 10px;
  height: 150px;
  width: 29% !important;
  border-radius: 30px;
  box-shadow: 0px 0px 18px -16px black;
  position: relative;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 18px;
    img {
      width: 34%;
      height: 110px;
    }
    div {
      width: 65%;
      height: 110px;
      // font-weight: bold;
      display: inline-block;
    }
  }
}

.active-subcats {
  display: inline-block;
}
.active-sub {
  color: white;
  font-weight: bold;
  &::before {
    transform: scale(1) !important;
    border-radius: 14px !important;
  }
}
.active-meals {
  display: inline-block;
}
@keyframes fade {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  50% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
