<template>
  <div class="side-bar">
    <router-link :to="{ name: 'home' }"><h2 class="logo">C.D</h2></router-link>
    <ul>
      <li
        class="category-name"
        @click="activateCatrgory($event)"
        v-for="(category, i) in categories"
        :key="i"
        :data-category="category.id"
      >
        {{ category.name }}
      </li>
    </ul>
    <div class="profile-buttons">
      <router-link :to="{ name: 'Profile' }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          fill="currentColor"
          class="bi bi-person-circle"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path
            fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
          />
        </svg>
      </router-link>
      <svg
        @click="logOut()"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        fill="currentColor"
        class="bi bi-box-arrow-right"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fill-rule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import { setTimeout } from "timers/promises";

export default {
  name: "SideBar",
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      let resOfCats = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/categories`
      );
      if (resOfCats.status == 200) {
        this.categories = resOfCats.data;
      } else {
        console.log(resOfCats);
      }
    },
    activateCatrgory(e) {
      if (
        e.target.classList[e.target.classList.length - 1] !== "active-category"
      ) {
        let categoryName = document.getElementsByClassName("category-name"),
          meal = document.getElementsByClassName("meal"),
          subCatName = document.getElementsByClassName("subcat-name"),
          activeSubs = [];

        for (let a = 0; a < categoryName.length; a++) {
          categoryName[a].classList.remove("active-category");
        }
        for (let w = 0; w < subCatName.length; w++) {
          subCatName[w].classList.remove("active-subcats");
          if (subCatName[w].dataset.category == e.target.dataset.category) {
            activeSubs.push(subCatName[w]);
          }
        }
        activeSubs.forEach((e, i) => {
          setTimeout(() => {
            e.classList.add("active-subcats");
          }, i * 100);
        });
        activeSubs = [];
        for (let r = 0; r < meal.length; r++) {
          meal[r].classList.remove("active-meals");
        }
        e.target.classList.add("active-category");
      }
    },
    logOut() {
      localStorage.clear();
      this.$router.push({ name: "LogIn" });
    },
  },
};
</script>
<style lang="scss">
.side-bar {
  height: 100vh;
  min-width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 20px 10px;
  color: #a6abb2 !important;
  background-color: white;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 1px 0px 10px 0px rgb(0 0 0 / 8%);
  .logo {
    color: #ff9b55;
    font-family: system-ui;
    font-size: 33px;
  }
  ul {
    height: 75%;
    width: 100%;
    margin: 0px 0px;
    padding: 10px 0px;
    li.category-name {
      position: relative;
      z-index: 3;
      list-style: none;
      padding: 10px 10px;
      margin: 10px 0px;
      transition: all 0.3s ease;
      &::before {
        content: "";
        transition: all 0.3s ease;
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        height: 100%;
        width: 95%;
        background: #ff9b55;
        border-radius: 100px;
        z-index: -1;
        transform: scale(0);
      }
    }
  }
  .profile-buttons {
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    svg {
      margin-top: 10px;
    }
  }
  a {
    color: #a6abb2 !important;
    text-decoration: none;
  }
  ul {
    padding: 0px;
  }
}
.active-category {
  color: white;
  font-weight: bold;
  border-radius: 10px;
  &::before {
    transform: scale(1) !important;
    border-radius: 14px !important;
  }
}
</style>
