<template>
  <div class="order-list text-center">
    <h4 class="mt-2 d-inline-block w-50 text-start">Order Menu</h4>
    <p class="d-inline-block w-50 text-end">{{ thisTime }}</p>
    <table class="table bg-light table-striped">
      <thead>
        <th scope="col">Name</th>
        <th scope="col">Qty</th>
        <th scope="col">Price</th>
        <th scope="col">Sales</th>
        <th scope="col">Tot. Price</th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <transition-group name="fade">
          <tr class="" v-for="(order, i) in $store.state.listOfOrders" :key="i">
            <td>{{ order.mealName }}</td>
            <td>
              <input
                class="w-input btn btn-outline-secondary"
                type="number"
                min="1"
                @change="checkQty($event, order.mealQty)"
                @keyup="checkQty($event, order.mealQty)"
                v-model="order.mealQty"
              />
            </td>
            <td>{{ order.mealPrice }}</td>
            <td>
              <input
                class="w-input btn btn-outline-secondary"
                type="number"
                min="0"
                max="100"
                @change="checkSales($event, order.mealSales)"
                @keyup="checkSales($event, order.mealSales)"
                v-model="order.mealSales"
              />
            </td>
            <td>
              {{
                parseInt(
                  (
                    order.mealQty * order.mealPrice -
                    (order.mealQty *
                      order.mealPrice *
                      (order.mealSales > 0 && order.mealSales <= 100
                        ? order.mealSales
                        : 0)) /
                      100
                  ).toFixed(2)
                )
              }}
            </td>
            <td>
              <span class="deleteorder">
                <svg
                  :data-order="order.mealName"
                  @click.stop="deleteOrder(order)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"
                  />
                </svg>
              </span>
            </td>
          </tr>
          <tr v-if="finalPrice">
            <td><h5>final price :</h5></td>
            <td>{{ finalPrice }} $</td>
          </tr>
        </transition-group>
      </tbody>
    </table>
    <div v-if="$store.state.listOfOrders.length > 0">
      <button @click="cancelTheList()" class="btn btn-outline-warning">
        cancel
      </button>
      <button @click="confirmTheList()" class="btn btn-outline-success">
        confirm
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "OrderList",
  props: ["meals", "orderIds"],
  data() {
    return {
      mealName: "",
      mealsQty: "",
      mealPrice: "",
      sales: 0,
      userId: "",
    };
  },
  computed: {
    thisTime: function () {
      let timeString = new Date();
      return timeString.toLocaleDateString();
    },

    finalPrice: function () {
      let price = 0;
      for (
        let order = 0;
        order < this.$store.state.listOfOrders.length;
        order++
      ) {
        price += parseInt(
          (
            this.$store.state.listOfOrders[order].mealQty *
              this.$store.state.listOfOrders[order].mealPrice -
            (this.$store.state.listOfOrders[order].mealQty *
              this.$store.state.listOfOrders[order].mealPrice *
              (this.$store.state.listOfOrders[order].mealSales > 0 &&
              this.$store.state.listOfOrders[order].mealSales <= 100
                ? this.$store.state.listOfOrders[order].mealSales
                : 0)) /
              100
          ).toFixed(2)
        );
      }
      return price;
    },
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.name = JSON.parse(user).name;
      this.email = JSON.parse(user).email;
      this.pass = JSON.parse(user).pass;
      this.userId = JSON.parse(user).id;
    } else {
      this.$router.push({ name: "LogIn" });
    }
  },
  methods: {
    deleteOrder(order) {
      this.$store.state.listOfOrders.splice(
        this.$store.state.listOfOrders.indexOf(order),
        1
      );
      console.log(this.$store.state.listOfOrders);
    },
    cancelTheList() {
      this.$store.state.listOfOrders = [];
    },
    async confirmTheList() {
      let result = await axios.post(
        `https://my-json-server.typicode.com/y-alhouri/api-test/orders?userId=${this.userId}`,
        {
          userId: this.userId,
          orders: this.$store.state.listOfOrders,
          time: this.thisTime,
          numOfOrders: this.$store.state.listOfOrders.length,
          finalPrice: this.finalPrice,
        }
      );
      if (result.status == 201) {
        this.$store.state.listOfOrders = [];
      } else {
        console.log("Something went wrong.Plaese, try again!");
      }
    },
    checkQty(e, qty) {
      console.log(e.target.value);
      if (e.target.value < 0) {
        e.target.value = 1;
        qty = 1;
      } else {
        console.log(qty);
      }
    },
    checkSales(e, sales) {
      if (e.target.value < 0 || e.target.value > 100) {
        sales = 0;
        e.target.value = 0;
      } else console.log(`${sales} is checked..`);
    },
  },
};
</script>
<style scoped>
.order-list {
  background-color: white;
  height: 100vh;
  padding: 15px 5px 10px 10px;
  border-radius: 30px;
  box-shadow: -5px 0px 10px 0px #00000014;
  position: fixed;
  right: 0px;
}
.w-input {
  width: 65px;
}
th {
  padding: 12px 0px;
}
/* transition classes */

/* 1. declare transition */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  width: 0px;
  transform: scaleX(0.01);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
  position: absolute;
}
</style>
