<template>
  <transition
    appear
    appear-from-class="from"
    ppear-to-class="to"
    appear-active-class="apa"
  >
    <div class="home row">
      <div class="col-1"><SideBar /></div>
      <div :class="{ 'col-7': isOrder, 'col-11': !isOrder }">
        <MealsDisplay />
      </div>

      <div :class="{ 'col-4': isOrder, 'col-0': !isOrder }">
        <OrderList />
      </div>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import SideBar from "@/components/SideBar.vue";
import MealsDisplay from "@/components/MealsDisplay.vue";
import OrderList from "@/components/OrderList.vue";

export default {
  name: "HomeView",
  data() {
    return {};
  },
  computed: {
    isOrder: function () {
      return this.$store.state.listOfOrders.length;
    },
  },
  components: {
    SideBar,
    MealsDisplay,
    OrderList,
  },
};
</script>
<style lang="scss">
.home {
  height: 98vh;
  div {
    transition: all 0.5s ease-in-out;
  }
}
.from {
  transform: translateX(100px);
  opacity: 0;
}
.to {
  transform: translateY(0px);
  opacity: 1;
}
.apa {
  transition: all 0.3s ease;
}
</style>
