<template>
  <div class="more-details container">
    <div class="py-3 fw-bold">
      More Details For Order Number : {{ orderId }}
    </div>
    <transition
      appear
      appear-from-class="from"
      ppear-to-class="to"
      appear-active-class="apa"
    >
      <table class="table bg-light table-striped">
        <thead>
          <th scope="col">Name</th>
          <th scope="col">Qty</th>
          <th scope="col">Price</th>
          <th scope="col">Sales</th>
          <th scope="col">Tot. Price</th>
          <th scope="col"></th>
        </thead>
        <tbody>
          <tr v-for="(order, i) in orders" :key="i">
            <td>{{ order.mealName }}</td>
            <td>
              {{ order.mealQty }}
            </td>
            <td>{{ order.mealPrice }}</td>
            <td>
              {{ order.mealSales }}
            </td>
            <td>
              {{
                parseInt(
                  (
                    order.mealQty * order.mealPrice -
                    (order.mealQty *
                      order.mealPrice *
                      (order.mealSales > 0 && order.mealSales <= 100
                        ? order.mealSales
                        : 0)) /
                      100
                  ).toFixed(2)
                )
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="finalPrice">
            <td>final price</td>
            <td>{{ finalPrice }}</td>
          </tr>
        </tfoot>
      </table>
    </transition>
    <router-link class="go-back" :to="{ name: 'Profile' }">
      Go back</router-link
    >
    <router-link class="go-home" :to="{ name: 'home' }">Go Home</router-link>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      userId: ``,
      name: ``,
      orderId: this.$route.params.orderId,
      orders: [],
    };
  },
  computed: {
    finalPrice: function () {
      let price = 0;
      for (let order = 0; order < this.orders.length; order++) {
        price += parseInt(
          (
            this.orders[order].mealQty * this.orders[order].mealPrice -
            (this.orders[order].mealQty *
              this.orders[order].mealPrice *
              (this.orders[order].mealSales > 0 &&
              this.orders[order].mealSales <= 100
                ? this.orders[order].mealSales
                : 0)) /
              100
          ).toFixed(2)
        );
      }
      return price;
    },
  },
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.name = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
      let cashOrders = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/orders?userId=${this.userId}&id=${this.orderId}`
      );
      if (cashOrders.status == 200) {
        this.orders = cashOrders.data[0].orders;
      }
    } else {
      this.$router.push({ name: "LogIn" });
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.go-home,
.go-back {
  display: inline-block;
  position: fixed;
  left: 54%;
  bottom: 30px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ff2352;
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    color: white;
    transform: scale(1.1);
  }
}
.go-home {
  background-color: #444;
  left: 40%;
}
</style>
