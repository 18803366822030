<template>
  <transition
    appear
    appear-from-class="from"
    ppear-to-class="to"
    appear-active-class="apa"
  >
    <div class="profile-form container">
      <div class="align-items-center row">
        <div class="cashier-name col-6">Cashier Name: {{ name }}</div>
        <form id="search" class="col-6">
          <input name="query" placeholder="Search.." v-model="searchQuery" />
        </form>
      </div>
      <div class="the-table">
        <DemoGrid
          class="col-10"
          :data="orders"
          :columns="gridColumns"
          :filter-key="searchQuery"
        >
        </DemoGrid>
      </div>
    </div>
  </transition>
  <router-link class="go-back" :to="{ name: 'home' }">Go Back </router-link>
</template>
<script>
import DemoGrid from "./MainGrid.vue";
import axios from "axios";
export default {
  components: {
    DemoGrid,
  },

  data: () => ({
    name: ``,
    userId: ``,
    searchQuery: "",
    gridColumns: ["id", "time", "numOfOrders", "finalPrice"],
    orders: [],
  }),
  async mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.name = JSON.parse(user).name;
      this.userId = JSON.parse(user).id;
      let cashOrders = await axios.get(
        `https://my-json-server.typicode.com/y-alhouri/api-test/orders?userId=${this.userId}`
      );
      if (cashOrders.status == 200) {
        this.orders = cashOrders.data;
        console.log(this.orders);
      }
    } else {
      this.$router.push({ name: "LogIn" });
    }
  },
  methods: {},
};
</script>
<style lang="scss">
#search {
  height: 10vh;
  text-align: right;
  display: flex;
  align-items: end;
  justify-content: end;

  input {
    height: 45px;
    // margin: 2%;
    border: none;
    width: 290px;
    text-indent: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #00000042;
  }
}
.the-table {
  margin-top: 10px;
  padding: 10px;
  // border-radius: 24px;
  height: 85vh;
  background: #fff;
  box-shadow: 0px 0px 20px #00000036;
  overflow-y: scroll;
  a {
    color: #505050;
    text-decoration: none;
    transition: all 0.5s ease;
    &:hover {
      color: #303030;
      // font-weight: bold;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(207, 167, 204);
  }
}
.cashier-name.col-6 {
  font-size: 29px;
  color: #898989;
  font-weight: bold;
  text-align: left;
}
.go-back {
  display: inline-block;
  position: fixed;
  left: 30px;
  bottom: 30px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ff2352;
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    color: white;
    transform: scale(1.1);
  }
}
</style>
